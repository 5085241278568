import { Color } from '@pafcloud/style';
import { Colors as Color_default } from './colors.default';

export const Colors = {
  ...Color_default,
  TurnoverPercentage: Color.Primitive.PrimaryContrast,
  BulletDone: Color.Primitive.PrimaryContrast,
  FallbackIconBackground: Color.Primitive.Gradient,
  FallbackIcon: Color.Primitive.PrimaryContrast,
  CardBorder: `1px solid ${Color.Primitive.AccentContrast}`,
};
